<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18666611366">1-866-661-1366</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site_hiver_2023.flash_dec_link')" target="_blank" class="cta red">{{ $t('site_hiver_2023.buy_tickets') }}</a>
      </div>
      <a href="tel:18666611366" class="phone-mobile"><img src="@/assets/img/phone4.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site_hiver_2023.flash_dec_link')" target="_blank" class="cta red">{{ $t('site_hiver_2023.buy_tickets') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>OFFRE D’APRÈS-NOËL - Carte Latitude</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          
          <li class="regular-text">La carte Latitude est valable en tout temps durant la saison de ski 2022-2023, sauf du 27 décembre 2022 au 5 janvier 2023 inclusivement et les 8 samedis suivants : 14, 21 et 28 janvier 2023, 11 et 18 février 2023, 4, 18 et 25 mars 2023.</li>
          
          <li class="regular-text">Lorsque les journées initiales sont épuisées, obtenez 15 % de rabais sur vos visites subséquentes. Rabais applicable sur un billet régulier 1-jour, tarification billetterie. </li>

        </ul>
        
        <h2 class="small-title">Achat</h2>
        <ul>
          
          <li class="regular-text">La carte Latitude doit être achetée en ligne ou par téléphone au 1-866-661-1366.  </li>
          <li class="regular-text">Pour profiter des journées additionnelles à 15%, ajoutez l’option Accès direct aux remontées et présentez-vous directement à la remontée. Un paiement sécurisé se fera automatiquement et le rabais correspondant à la journée sera appliqué, selon le cas. Vous pouvez activer l’option ADR en personne au Service à la clientèle. </li>
          <li class="regular-text">Les journées additionnelles à 15% de rabais peuvent être achetées à la billetterie.</li>
          <li class="regular-text">La carte Latitude est offerte aux résidents canadiens seulement. Preuve de résidence requise pour récupérer sa carte (carte d’identité délivrée par le gouvernement ou facture de services publics datée de moins de 6 mois).</li>

        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          
          <li class="regular-text">La carte Latitude sera ajoutée sur la passe Tremblant que vous détenez déjà. Si vous achetez ce produit en ligne, vous devez ouvrir la session avec votre profil existant.</li>
          <li class="regular-text">Tout nouveau détenteur peut récupérer sa Carte Latitude à l’uns des comptoirs
          multiservices. Pièce d’identité avec photo obligatoire.</li>
          <li class="regular-text">Tout détenteur n’ayant pas reçu sa carte Latitude à temps pour sa visite doit se présenter au Service à la clientèle ou à l’un des comptoirs multiservices pour récupérer sa passe Tremblant (pièce d’identité avec photo obligatoire).</li>

        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Non remboursable</li>
          <li class="regular-text">Journée expirée inutilisée ? La valeur payée sera applicable à l’achat d’un billet 1 jour à prix courant.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Limite d'un billet journalier escompté par détenteur, par jour.</li>
          <li class="regular-text">Les rabais ne s’appliquent pas aux billets de remontées ½ journée.</li>
          <li class="regular-text">Produit et rabais incessibles (ne peuvent être transférés d'une personne à une autre).</li>
          <li class="regular-text">La carte Latitude est offerte en exclusivité aux résidents canadiens. Si vous tentez d’acheter cette carte sans être résident canadien, votre carte ne sera pas activée.</li>
        </ul>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">BOXING DAY - Latitude Card<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">The Latitude Card is valid at all times during the 2022/23 ski season, except from December 27, 2022, to January 5, 2023, inclusive and the following 8 Saturdays: January 14, 21 and 28, February 11 and 18, March 4, 18 and 25, 2023.</li>
          <li class="regular-text">The Holiday Season Latitude Card is not back this season. If you wish to come during the exclusion dates, please consult the multi-day ticket.</li>
          <li class="regular-text">Once all initial days are used, your card entitles you to a 15% discount on additional visits (discount applicable on a 1-day regular ticket office ticket)</li>
        </ul>

        <h2 class="small-title">Purchase</h2>
        <ul>
          <li class="regular-text">Latitude Card must be purchased online or by phone at 1-866-661-1366.</li>
          <li class="regular-text">To take advantage of the 15% discount on additional days, add the Direct-to-lift access to your Tremblant pass and go directly to the lifts. A secure payment with your applicable discount will be automatically applied to your card. You can activate the DTL option at our Guest Service Desk.</li>
          <li class="regular-text">Additional days at 15% off can be purchased at the ticket office.</li>
          <li class="regular-text">The Latitude Card is available to Canadian residents only. Proof of residency required to pick up your card (government-issued photo ID or utility bill dated less than 6 months old)</li>

        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">The Latitude Card will be added to your existing Tremblant Pass. If you purchase the product online, log in with your actual profile.</li>
          <li class="regular-text">New passholders must pick up their Latitude Card at a multiservice counter. Photo
          ID required.</li>
          <li class="regular-text">Any Passholder who has not received their Latitude Card in time for their visit must pick up their Tremblant Pass at one of our multiservice counters (photo ID required).</li>

        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Non refundable</li>
          <li class="regular-text">Expired and unused days: The prepaid value will be applied to the purchase of a day ticket at the regular price</li>

        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Maximum of one regular discounted ticket per cardholder, per day.</li>
          <li class="regular-text">Rebates not applicable on half-day tickets.</li>
          <li class="regular-text">Non-transferable (product and discounts cannot be transferred from one person to the next).</li>
          <li class="regular-text">The Latitude Card is offered exclusively to Canadian residents. If you try to purchase this card and are not a Canadian resident, your pass will not be activated. </li>

        </ul>

     </div>
      
    </section>

    <footer-bar></footer-bar>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
